import { EnvelopeIcon, PlayCircleIcon } from "@heroicons/react/20/solid"
import Link from "next/link"
import TourModal from "./modal/TourModal"

const className =
    "flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-gray-100"

export default function MenuCallsToAction() {
    return (
        <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
            <TourModal>
                <button className={className}>
                    <PlayCircleIcon
                        className="h-5 w-5 flex-none text-gray-400"
                        aria-hidden="true"
                    />
                    Interactive demo
                </button>
            </TourModal>
            <Link href="/demo" className={className}>
                <EnvelopeIcon
                    className="h-5 w-5 flex-none text-gray-400"
                    aria-hidden="true"
                />
                Contact sales
            </Link>
        </div>
    )
}
