"use client"
/* eslint @typescript-eslint/no-unsafe-assignment: 0 */
/* eslint @typescript-eslint/no-unsafe-member-access: 0 */
/* eslint @typescript-eslint/no-unsafe-call: 0 */

import { useContext, useEffect, useRef } from "react"
import { HubspotContext } from "./HubspotContext"

type Props = {
    formId: string
}

export default function HubspotForm(props: Props) {
    const hubspot = useContext(HubspotContext)
    const createdRef = useRef(false)

    useEffect(() => {
        const anyWindow = window as any
        if (hubspot.loaded && !createdRef.current) {
            createdRef.current = true
            anyWindow.hbspt.forms.create({
                region: "na1",
                portalId: "43810739",
                formId: props.formId,
                target: "#hubspotForm",
            })
        }
    }, [hubspot])

    return <div id="hubspotForm" className="hubspotForm lg:flex-auto"></div>
}
