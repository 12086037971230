"use client"

import accesslyIcon from "@files/icon-accessly.svg"
import { Dialog } from "@headlessui/react"
import { Bars3Icon, XMarkIcon } from "@heroicons/react/20/solid"
import Image from "next/image"
import Link from "next/link"
import { useEffect, useState } from "react"
import DemoModal from "../modal/DemoModal"
import styles from "./Header.module.scss"
import Products from "./ProductsMenu"
import Solutions from "./SolutionsMenu"

const navigation = [
    { name: "Products", href: "#", component: Products },
    { name: "Solutions", href: "#", component: Solutions },
    { name: "Pricing", href: "/pricing", component: null },
    { name: "FAQs", href: "/faqs", component: null },
    { name: "About us", href: "/about", component: null },
]

export default function Header() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const [isShrink, setIsShrink] = useState(false)

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setIsShrink(true)
            } else {
                setIsShrink(false)
            }
        }

        window.addEventListener("scroll", handleScroll)

        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [])
    return (
        <div>
            <nav
                className={`${styles.navContainer} mx-auto ${
                    isShrink ? styles.shrink : ""
                }`}
            >
                <div className="relative flex items-center justify-between gap-8 px-4">
                    <div className="col-span-1">
                        <div className="logo relative flex flex-col">
                            <Link href="/">
                                <svg
                                    width="122"
                                    height="24"
                                    viewBox="0 0 122 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M0.046875 12.7624C0.046875 7.90588 3.94334 4.43294 8.51745 4.43294C11.4257 4.43294 12.8092 5.61882 13.5998 6.91765H13.8821L14.4186 4.8H17.6657L15.181 18.9176H11.9339L12.1033 16.9694H11.821C10.7198 18.3247 8.94098 19.3129 6.59745 19.3129C2.84216 19.3129 0.046875 16.8282 0.046875 12.7624ZM3.63275 12.6212C3.63275 15.1341 5.2704 16.3482 7.78333 16.3482C10.7763 16.3482 13.1198 13.92 13.1198 10.9271C13.1198 8.80941 11.5669 7.39765 8.99745 7.39765C5.86334 7.39765 3.63275 9.45882 3.63275 12.6212Z"
                                        fill="#774AF4"
                                    />
                                    <path
                                        d="M17.3151 12.7059C17.3151 7.84941 21.2116 4.40471 26.3504 4.40471C30.8398 4.40471 33.6351 6.83294 33.7198 10.7859H30.1339C29.9928 8.55529 28.4963 7.36941 26.1528 7.36941C23.0469 7.36941 20.901 9.54353 20.901 12.6212C20.901 15.0494 22.4822 16.3482 24.9669 16.3482C27.4516 16.3482 29.2304 14.9647 29.8798 12.8471H33.5222C32.6751 16.7153 29.2586 19.3129 24.7692 19.3129C20.1951 19.3129 17.3151 16.7718 17.3151 12.7059Z"
                                        fill="#774AF4"
                                    />
                                    <path
                                        d="M34.1415 12.7059C34.1415 7.84941 38.038 4.40471 43.1768 4.40471C47.6662 4.40471 50.4615 6.83294 50.5462 10.7859H46.9603C46.8192 8.55529 45.3227 7.36941 42.9792 7.36941C39.8733 7.36941 37.7274 9.54353 37.7274 12.6212C37.7274 15.0494 39.3086 16.3482 41.7933 16.3482C44.278 16.3482 46.0568 14.9647 46.7062 12.8471H50.3486C49.5015 16.7153 46.085 19.3129 41.5956 19.3129C37.0215 19.3129 34.1415 16.7718 34.1415 12.7059Z"
                                        fill="#774AF4"
                                    />
                                    <path
                                        d="M50.968 12.7059C50.968 7.84941 54.8079 4.40471 59.9468 4.40471C65.1421 4.40471 67.9938 7.82118 67.062 12.6212H54.5256C54.5538 15.1059 56.2762 16.3765 58.6762 16.3765C60.7656 16.3765 62.4032 15.36 63.2785 13.7788L67.0338 13.8071C65.8479 17.1388 62.5726 19.3129 58.4785 19.3129C53.9044 19.3129 50.968 16.7718 50.968 12.7059ZM54.9774 10.2212H63.6456C63.4762 8.38588 62.0644 7.34118 59.7491 7.34118C57.4338 7.34118 55.655 8.49882 54.9774 10.2212Z"
                                        fill="#774AF4"
                                    />
                                    <path
                                        d="M67.5678 13.4118H71.0125C71.1819 15.7271 72.3396 16.5741 74.8525 16.5741C77.196 16.5741 78.5796 15.8965 78.6078 14.5976C78.636 14.0047 78.269 13.6376 77.0831 13.44L72.6219 12.6494C70.109 12.1976 68.6408 11.0682 68.6408 9.06353C68.6972 6.07059 71.6902 4.40471 75.8972 4.40471C80.2737 4.40471 82.6172 6.57882 82.7301 9.99529H79.2854C79.116 7.79294 77.9019 7.14353 75.6431 7.14353C73.2996 7.14353 72.1984 7.84941 72.1984 8.97882C72.1984 9.51529 72.5655 9.88235 73.6666 10.08L78.1278 10.8706C80.6972 11.3224 82.1654 12.5365 82.1372 14.4847C82.109 17.7035 79.116 19.3129 74.7396 19.3129C70.0243 19.3129 67.6808 17.2235 67.5678 13.4118Z"
                                        fill="#774AF4"
                                    />
                                    <path
                                        d="M82.8777 13.4118H86.3224C86.4918 15.7271 87.6494 16.5741 90.1624 16.5741C92.5059 16.5741 93.8894 15.8965 93.9177 14.5976C93.9459 14.0047 93.5788 13.6376 92.393 13.44L87.9318 12.6494C85.4189 12.1976 83.9506 11.0682 83.9506 9.06353C84.0071 6.07059 87 4.40471 91.2071 4.40471C95.5835 4.40471 97.9271 6.57882 98.04 9.99529H94.5953C94.4259 7.79294 93.2118 7.14353 90.953 7.14353C88.6094 7.14353 87.5083 7.84941 87.5083 8.97882C87.5083 9.51529 87.8753 9.88235 88.9765 10.08L93.4377 10.8706C96.0071 11.3224 97.4753 12.5365 97.4471 14.4847C97.4188 17.7035 94.4259 19.3129 90.0494 19.3129C85.3342 19.3129 82.9906 17.2235 82.8777 13.4118Z"
                                        fill="#774AF4"
                                    />
                                    <path
                                        d="M104.484 0L101.152 18.9176H97.7076L101.039 0H104.484Z"
                                        fill="#774AF4"
                                    />
                                    <path
                                        d="M108.044 18.9176L103.978 4.8H107.733L110.444 16.0376H110.811L117.362 4.8H121.371L110.613 21.9953C109.625 23.6047 108.976 24 107.169 24H102.764L103.301 21.0353H106.999C107.677 21.0353 107.988 20.8376 108.298 20.3012L109.145 18.9176H108.044Z"
                                        fill="#774AF4"
                                    />
                                </svg>
                            </Link>
                        </div>
                    </div>
                    <ul className="hidden items-center gap-8 text-sm sm:flex">
                        <li className="col-span-1">
                            <Link
                                className="nav-link font-semibold leading-6 text-gray-900"
                                href="/"
                            >
                                Home
                            </Link>
                        </li>
                        <li className="col-span-1">
                            <Solutions />
                        </li>
                        <li className="col-span-1">
                            <Products />
                        </li>
                        <li className="col-span-1">
                            <Link
                                className="nav-link font-semibold leading-6 text-gray-900"
                                href="/pricing"
                            >
                                Pricing
                            </Link>
                        </li>
                        <li className="col-span-1">
                            <Link
                                className="nav-link font-semibold leading-6 text-gray-900"
                                href="/faqs"
                            >
                                FAQ
                            </Link>
                        </li>
                        <li className="col-span-1">
                            <Link
                                className="nav-link font-semibold leading-6 text-gray-900"
                                href="/about"
                            >
                                About us
                            </Link>
                        </li>
                    </ul>
                    <div className="flex items-center gap-2 text-black">
                        <Link
                            href="https://new-dev.accessly.com.au/static/auth/login"
                            className="hidden h-full sm:flex"
                        >
                            <div className="button-white-juicy h-full">
                                Log In
                            </div>
                        </Link>
                        <Link href="/demo" className="col-span-1">
                            <div className="button-dark-main">
                                Request a demo
                            </div>
                        </Link>
                        <div className="ml-4 flex lg:hidden">
                            <button
                                type="button"
                                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                                onClick={() => setMobileMenuOpen(true)}
                            >
                                <span className="sr-only">Open main menu</span>
                                <Bars3Icon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                />
                            </button>
                        </div>
                    </div>
                </div>
            </nav>
            <Dialog
                as="div"
                className="lg:hidden"
                open={mobileMenuOpen}
                onClose={setMobileMenuOpen}
            >
                <div className="fixed inset-0 z-10" />
                <Dialog.Panel
                    className="fixed inset-y-0 right-0 z-[999999999] w-full overflow-y-auto bg-white px-6 py-6
                        sm:max-w-sm sm:ring-1 sm:ring-gray-900/10"
                >
                    <div className="flex items-center gap-x-6">
                        <a href="#" className="-m-1.5 p-1.5">
                            <span className="sr-only">Your Company</span>
                            <Image
                                className="h-8 w-auto"
                                src={accesslyIcon}
                                alt=""
                            />
                        </a>
                        <DemoModal
                            className="ml-auto rounded-md bg-violet-600 px-3 py-2 text-sm font-semibold text-white
                                shadow-sm hover:bg-violet-500 focus-visible:outline focus-visible:outline-2
                                focus-visible:outline-offset-2 focus-visible:outline-violet-600"
                            label="Request a demo"
                        />
                        <button
                            type="button"
                            className="-m-2.5 rounded-md p-2.5 text-gray-700"
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="space-y-2 py-6">
                                {navigation.map((item) =>
                                    item.component ? (
                                        <div
                                            className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900
                                                hover:bg-gray-50"
                                            key={item.name}
                                        >
                                            <item.component />
                                        </div>
                                    ) : (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900
                                                hover:bg-gray-50"
                                        >
                                            {item.name}
                                        </a>
                                    ),
                                )}
                            </div>
                            <div className="py-6">
                                <Link
                                    href="https://new-dev.accessly.com.au/static/auth/login"
                                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7
                                        text-gray-900 hover:bg-gray-50"
                                >
                                    Log in
                                </Link>
                            </div>
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </div>
    )
}
