"use client"

import ContractorIcon from "@files/icon-contractor.svg"
import FMIcon from "@files/icon-fm.svg"
import InductionsIcon from "@files/icon-inductions.svg"
import KeysafeIcon from "@files/icon-keysafe.svg"
import VisitorIcon from "@files/icon-visitor.svg"
import { Popover, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/20/solid"
import Image from "next/image"
import Link from "next/link"
import { Fragment } from "react"
import MenuCallsToAction from "../MenuCallsToAction"

const products = [
    {
        name: "Facility Manager",
        description: "Efficiently manage your portfolio",
        href: "/products/facility-manager",
        icon: FMIcon,
    },
    {
        name: "Visitor",
        description: "Manage and monitor visitor access",
        href: "/products/visitors",
        icon: VisitorIcon,
    },
    {
        name: "Contractor",
        description: "Manage your contractors and their compliance",
        href: "/products/contractor",
        icon: ContractorIcon,
    },
    {
        name: "Inductions",
        description: "Onboard your contractors and staff",
        href: "/products/inductions",
        icon: InductionsIcon,
    },
    {
        name: "Keysafe",
        description: "Secure your keys with our smart key safes",
        href: "/products/keysafe",
        icon: KeysafeIcon,
    },
]

export default function ProductsMenu() {
    return (
        <Popover className="relative">
            <Popover.Button
                className="inline-flex items-center gap-x-1 border-none font-semibold leading-6
                    text-gray-900 outline-none"
            >
                <span>Products</span>
                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
            </Popover.Button>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
            >
                <Popover.Panel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">
                    <div
                        className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm
                            leading-6 shadow-lg ring-1 ring-gray-900/5"
                    >
                        <div className="p-4">
                            {products.map((item) => (
                                <div
                                    key={item.name}
                                    className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50"
                                >
                                    <div
                                        className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50
                                            group-hover:bg-white"
                                    >
                                        <Image
                                            src={item.icon}
                                            className="h-8 w-8 text-gray-600 group-hover:text-violet-600"
                                            aria-hidden="true"
                                            alt={item.name}
                                        />
                                    </div>
                                    <div>
                                        <Link
                                            href={item.href}
                                            className="font-semibold text-gray-900"
                                        >
                                            {item.name}
                                            <span className="absolute inset-0" />
                                        </Link>
                                        <p className="mt-1 text-gray-600">
                                            {item.description}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <MenuCallsToAction />
                    </div>
                </Popover.Panel>
            </Transition>
        </Popover>
    )
}
