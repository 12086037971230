"use client"

import { Dialog, Transition } from "@headlessui/react"
import { Fragment, useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"
import hubspot from "../Hubspot"
import HubspotForm from "../HubspotForm"

type Props = {
    className: string
    label: string
}

export default function DemoModal(props: Props) {
    const [isOpen, setIsOpen] = useState(false)

    const bodyRef = useRef<Element | null>(null)
    useEffect(() => {
        bodyRef.current = document.body
    }, [])

    return (
        <>
            <button className={props.className} onClick={() => setIsOpen(true)}>
                {props.label}
            </button>
            {bodyRef.current &&
                createPortal(
                    <Transition appear show={isOpen} as={Fragment}>
                        <Dialog
                            as="div"
                            className="relative z-[9999999]"
                            onClose={() => setIsOpen(false)}
                        >
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="fixed inset-0 bg-black/25" />
                            </Transition.Child>

                            <div className="fixed inset-0 overflow-y-auto">
                                <div className="flex min-h-full items-center justify-center p-4 text-center">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 scale-95"
                                        enterTo="opacity-100 scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 scale-100"
                                        leaveTo="opacity-0 scale-95"
                                    >
                                        <Dialog.Panel
                                            className="w-full max-w-md transform overflow-visible rounded-2xl bg-white p-6 text-left
                                                align-middle shadow-xl transition-all"
                                        >
                                            <Dialog.Title
                                                as="h3"
                                                className="text-lg font-medium leading-6 text-gray-900"
                                            >
                                                Get started with Accessly
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500">
                                                    Fill out the form below and
                                                    we'll be in touch to
                                                    schedule a demo.
                                                </p>

                                                <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-sm">
                                                    <HubspotForm
                                                        formId={
                                                            hubspot.formIds.demo
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </Dialog.Panel>
                                    </Transition.Child>
                                </div>
                            </div>
                        </Dialog>
                    </Transition>,
                    document.body,
                )}
        </>
    )
}
