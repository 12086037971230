import ctaImage from "@files/cta.png"
import Image from "next/image"
import DemoModal from "../modal/DemoModal"

export function Cta() {
    return (
        <div className="mt-4 bg-white">
            <div className="mx-auto w-full">
                <div
                    className="relative isolate overflow-hidden rounded-xl bg-gray-900 px-6 pt-24 text-center
                        shadow-2xl sm:rounded-xl sm:px-16"
                >
                    <h2 className="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-white sm:text-4xl">
                        Ready to take your facilities management to the next
                        level?
                    </h2>
                    <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">
                        Get in touch and let us show you how we can help you
                        streamline your operations, increase your teams
                        efficiency and reduce your costs with our all-in-one
                        facilities management platform
                    </p>
                    <div className="mt-10 flex items-center justify-center gap-x-6">
                        <DemoModal
                            className="button-white-juicy"
                            label="Get started"
                        />
                    </div>
                    <div className="mx-auto max-w-screen-2xl">
                        <Image
                            src={ctaImage}
                            alt="CTA"
                            className="col-span-2 w-[48rem] max-w-none shadow-xl sm:w-full md:-ml-4 lg:-ml-0"
                        />
                    </div>
                    <svg
                        viewBox="0 0 1024 1024"
                        className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2
                            [mask-image:radial-gradient(closest-side,white,transparent)]"
                        aria-hidden="true"
                    >
                        <circle
                            cx={512}
                            cy={512}
                            r={512}
                            fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)"
                            fillOpacity="0.7"
                        />
                        <defs>
                            <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
                                <stop stopColor="#7775D6" />
                                <stop offset={1} stopColor="#E935C1" />
                            </radialGradient>
                        </defs>
                    </svg>
                </div>
            </div>
        </div>
    )
}
