"use client"

import { Dialog, Transition } from "@headlessui/react"
import {
    Fragment,
    ReactElement,
    cloneElement,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react"
import { createPortal } from "react-dom"
import { useMediaQuery } from "react-responsive"

type Props = {
    children: ReactElement
}

export default function TourModal(props: Props) {
    const isMouseDevice = useMediaQuery({ query: "(pointer: fine)" })
    const [isOpen, setIsOpen] = useState(false)
    const onClick = useCallback(() => {
        if (isMouseDevice) {
            window.open(
                "https://new-dev.accessly.com.au/static/visitors",
                "_blank",
            )
        } else {
            setIsOpen(true)
        }
    }, [isMouseDevice])

    const bodyRef = useRef<Element | null>(null)
    useEffect(() => {
        bodyRef.current = document.body
    }, [])

    return (
        <>
            {cloneElement(props.children, { onClick })}
            {bodyRef.current &&
                createPortal(
                    <Transition appear show={isOpen} as={Fragment}>
                        <Dialog
                            as="div"
                            className="relative z-[9999999]"
                            onClose={() => setIsOpen(false)}
                        >
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="fixed inset-0 bg-black/25" />
                            </Transition.Child>

                            <div className="fixed inset-0 overflow-y-auto">
                                <div className="flex min-h-full items-center justify-center p-4 text-center">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 scale-95"
                                        enterTo="opacity-100 scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 scale-100"
                                        leaveTo="opacity-0 scale-95"
                                    >
                                        <Dialog.Panel
                                            className="w-full max-w-md transform overflow-visible rounded-2xl bg-white p-6 text-left
                                                align-middle shadow-xl transition-all"
                                        >
                                            <Dialog.Title
                                                as="h3"
                                                className="text-lg font-medium leading-6 text-gray-900"
                                            >
                                                For the best experience use a
                                                laptop or desktop
                                            </Dialog.Title>
                                        </Dialog.Panel>
                                    </Transition.Child>
                                </div>
                            </div>
                        </Dialog>
                    </Transition>,
                    document.body,
                )}
        </>
    )
}
