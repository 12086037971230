"use client"

import { AnimatePresence, motion } from "framer-motion"
import { usePathname } from "next/navigation"
import { PropsWithChildren } from "react"
import Footer from "./_components/Footer"
import Header from "./_components/Header/Header"

export default function Template(props: PropsWithChildren) {
    const pathname = usePathname()

    return (
        <AnimatePresence mode="wait">
            <motion.div key={pathname}>
                <Header />
                <main className="min-h-full antialiased">{props.children}</main>
                <Footer />
                <motion.div
                    className="slide-in"
                    initial={{ scaleY: 0 }}
                    animate={{ scaleY: 0 }}
                    exit={{ scaleY: 1 }}
                    transition={{
                        duration: 0.4,
                        ease: [0.22, 1, 0.36, 1],
                    }}
                ></motion.div>
                <motion.div
                    className="slide-out"
                    initial={{ scaleY: 1 }}
                    animate={{ scaleY: 0 }}
                    exit={{ scaleY: 0 }}
                    transition={{
                        duration: 0.4,
                        ease: [0.22, 1, 0.36, 1],
                    }}
                ></motion.div>
            </motion.div>
        </AnimatePresence>
    )
}
