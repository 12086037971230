import { Popover, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/20/solid"
import Link from "next/link"
import { Fragment } from "react"
import MenuCallsToAction from "../MenuCallsToAction"

const solutions = [
    {
        name: "Facilities Management",
        description: "Managing your facilities",
        href: "/solutions/facilities-management",
        icon: (
            <i className="ri-building-2-fill text-2xl text-gray-600 group-hover:text-violet-600" />
        ),
    },
    {
        name: "Visitor Management",
        description: "Managing visitors to your properties",
        href: "/solutions/access-management",
        icon: (
            <i className="ri-map-pin-user-fill text-2xl text-gray-600 group-hover:text-violet-600" />
        ),
    },
    {
        name: "Contractor Compliance",
        description: "Contractor pre-qualification",
        href: "/solutions/contractor-compliance",
        icon: (
            <i className="ri-user-follow-fill text-2xl text-gray-600 group-hover:text-violet-600" />
        ),
    },
    {
        name: "Induction Workflows",
        description: "Onboarding contractors and staff",
        href: "/solutions/induction-workflows",
        icon: (
            <i className="ri-list-check-3 text-2xl text-gray-600 group-hover:text-violet-600" />
        ),
    },
    {
        name: "Smart Key Security",
        description: "Secure and manage your keys",
        href: "/solutions/smartkey-security",
        icon: (
            <i className="ri-key-2-fill text-2xl text-gray-600 group-hover:text-violet-600" />
        ),
    },
]

export default function Solutions() {
    return (
        <Popover className="relative">
            <Popover.Button
                className="inline-flex items-center gap-x-1 border-none font-semibold leading-6
                    text-gray-900 outline-none"
            >
                <span>Solutions</span>
                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
            </Popover.Button>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
            >
                <Popover.Panel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">
                    <div
                        className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm
                            leading-6 shadow-lg ring-1 ring-gray-900/5"
                    >
                        <div className="p-4">
                            {solutions.map((item) => (
                                <div
                                    key={item.name}
                                    className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50"
                                >
                                    <div
                                        className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50
                                            group-hover:bg-white"
                                    >
                                        {item.icon}
                                    </div>
                                    <div>
                                        <Link
                                            href={item.href}
                                            className="font-semibold text-gray-900"
                                        >
                                            {item.name}
                                            <span className="absolute inset-0" />
                                        </Link>
                                        <p className="mt-1 text-gray-600">
                                            {item.description}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <MenuCallsToAction />
                    </div>
                </Popover.Panel>
            </Transition>
        </Popover>
    )
}
